import {createStore} from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
    state: {
        todos: [
            {id: 1, text: '...', done: true},
            {id: 2, text: '...', done: false}
        ]
    },
    getters: {
        doneTodos(state) {
            return state.todos.filter(todo => todo.done)
        },
        doneTodosCount (state, getters) {
            return getters.doneTodos.length
        }
    },
    modules: {},
    strict: debug
});

export default store;