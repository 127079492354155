import {createApp} from 'vue';
import App from '@/view/App';
import http from '@/core/plugins/axios';
import store from '@/core/services/store';
import router from '@/router';
import auth from '@/core/plugins/auth';
import i18n from '@/core/plugins/i18n';
import vant from 'vant';
import 'vant/lib/index.css';
import pusher from "@/core/plugins/pusher";
import mapkit from "@solidsilver/mapkitjs";
import {MAP_KIT_KEY} from "@/config/api";
// import Pusher from "pusher-js";

const app = createApp(App);
// app.config.devtools = true;
// Pusher.logToConsole = true;

mapkit.init({
    authorizationCallback(done) {
        done(MAP_KIT_KEY)
    },
    language: "en"
});

app
    .use(http)
    .use(store)
    .use(router)
    .use(auth)
    .use(i18n)
    .use(vant)
    .use(pusher)
    .mount('#app');
