import {useAuth} from '@websanova/vue-auth/src/v3.js';
import {LOGIN_URL} from "@/config/api";

export default function useAuthComp() {
    const http = useAuth();
    let prevLocation;

    function login(data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.login({
                url: LOGIN_URL,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function gps() {
        return new Promise((resolve, reject) => {
            // console.log('Geolocation');
            if (!("geolocation" in navigator)) {
                // console.log('Geolocation', 'Geolocation is not available.')
                reject(Error('Geolocation is not available.'));
            }
            // console.log('Geolocation', 'prepare');
            navigator.geolocation.getCurrentPosition(pos => {
                prevLocation = pos;
                // console.log('Geolocation', pos)
                resolve(pos);
            }, err => {
                // console.log('Geolocation', err)
                if (prevLocation !== null) {
                    resolve(prevLocation);
                } else {
                    reject(err);
                }
            }, {
                timeout: 2000,
                maximumAge: 10000,
                enableHighAccuracy: true
            });
        });
    }

    function fetch(data) {
        return http.fetch(data);
    }

    function refresh(data) {
        return http.refresh(data);
    }

    function user() {
        let val = JSON.parse(http.remember());
        return val ?? {
            id: 0,
            username: 'doweing-guest',
            fullname: 'guest',
            name: 'guest',
            avatar_url: '/media/images/user.png',
        }
    }

    function logout() {
        http.unremember();
        return http.logout();
    }

    function check(type = 'type') {
        return http.check(type);
    }

    return {
        user,
        fetch,
        gps,
        refresh,
        login,
        logout,
        check,
    }
}
