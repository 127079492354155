import {createAuth} from '@websanova/vue-auth/src/v3.js';
import driverAuthBearer from '@/core/module/authModule';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';
import {API_USER, GET, LOGIN_URL, POST, REFRESH_URL} from "@/config/api";

driverOAuth2Google.params.client_id = 'driverOAuth2Google';
driverOAuth2Facebook.params.client_id = 'driverOAuth2Facebook';

export default (app) => {
    app.use(createAuth({
        plugins: {
            http: app.axios,
            router: app.router,
        },
        drivers: {
            http: driverHttpAxios,
            auth: driverAuthBearer,
            router: driverRouterVueRouter,
            oauth2: {
                google: driverOAuth2Google,
                facebook: driverOAuth2Facebook,
            }
        },
        options: {
            rolesKey: 'type',
            tokenName: 'idToken',
            fetchUser: false,
            refreshTokenName: 'refresh_token',
            authRedirect: false,
            logoutData: {url: false, method: false, redirect: false},
            loginData: {url: LOGIN_URL, method: POST, redirect: false},
            refreshData: {url: REFRESH_URL, method: POST, interval: 60},
            fetchData: {url: API_USER, method: GET},
            notFoundRedirect: false,
        }
    }));
}