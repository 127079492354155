export const APP_STORE_URL = process.env.VUE_APP_APP_STORE_URL;
export const PLAY_STORE_URL = process.env.VUE_APP_PLAY_STORE_URL;

export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY;
export const GOOGLE_MAP_KEY = process.env.VUE_APP_GOOGLE_MAP_KEY;
export const MAP_KIT_KEY = process.env.VUE_APP_MAP_KIT_KEY;
export const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY;

export const LOGIN_URL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=' + FIREBASE_API_KEY
export const REFRESH_URL = 'https://securetoken.googleapis.com/v1/token?key=' + FIREBASE_API_KEY

export const API_URL = process.env.VUE_APP_API_URL

export const PUSHER_HTTP_HOST = process.env.VUE_APP_PUSHER_HTTP_HOST
export const PUSHER_AUTH_URL = process.env.VUE_APP_PUSHER_AUTH_URL

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const OPTIONS = 'OPTIONS';

// export const PUSHER_channelGroup = 'presence-group.'
export const PUSHER_channelGroup = 'group.'
export const PUSHER_eventSubSucceed = 'pusher:subscription_succeeded'
export const PUSHER_eventSubError = 'pusher:subscription_error'
export const PUSHER_eventMessageCreated = 'message.created'
export const PUSHER_eventMessageUpdated = 'message.updated'
export const PUSHER_eventMessageDeleted = 'message.deleted'
export const PUSHER_eventLocationCreated = 'location.created'
export const PUSHER_eventLocation = 'client-location' // Get user location.
export const PUSHER_eventPointCreated = 'point.created'
export const PUSHER_eventPointUpdated = 'point.updated'
export const PUSHER_eventPointDeleted = 'point.deleted'
export const PUSHER_eventPointShared = 'point.shared'
export const PUSHER_eventRouteCreated = 'route.created'
export const PUSHER_eventRouteDeleted = 'route.deleted'
export const PUSHER_eventRouteShared = 'route.shared'
export const PUSHER_eventDrawingCreated = 'drawing.created'
export const PUSHER_eventDrawingDeleted = 'drawing.deleted'
export const PUSHER_eventDrawingShared = 'drawing.shared'
export const PUSHER_eventPostCreated = 'post.created'
export const PUSHER_eventPostUpdated = 'post.updated'
export const PUSHER_eventPostDeleted = 'post.deleted'
export const PUSHER_eventPostShared = 'post.shared'
export const PUSHER_eventCommentCreated = 'comment.created'
export const PUSHER_eventCommentDeleted = 'comment.deleted'
export const PUSHER_eventPictureCreated = 'picture.created'
export const PUSHER_eventPictureDeleted = 'picture.deleted'
export const PUSHER_eventAudioCreated = 'audio.created'
export const PUSHER_eventAudioDeleted = 'audio.deleted'
export const PUSHER_eventVideoCreated = 'video.created'
export const PUSHER_eventVideoDeleted = 'video.deleted'

export const API_USER = API_URL + '/v1/users/me';
//GROUP
export const API_GROUP = API_URL + '/v1/groups';
export const API_ACHIEVEMENT = API_URL + '/v1/achievements';
export const API_AUDIO = API_URL + 'v1/audios';
export const API_CATEGORY = API_URL + '/v1/categories';
export const API_LOCATION = API_URL + '/v1/locations';
export const API_COMMENT = API_URL + '/v1/comments';
export const API_MESSAGE = API_URL + '/v1/messages';
export const API_POINT = API_URL + '/v1/points';
export const API_POST = API_URL + '/v1/posts';
export const API_ROUTE = API_URL + '/v1/routes';
export const API_USER_OTHER = API_URL + '/v1/users';

export const BASE_API = (API) => {
    return BASE_API + API;
}
