<template>
  <router-view/>
</template>

<style lang="scss">
@import "~@/assets/fonts/nunito/nunito.css";
@import "~@/assets/styles/sass/app.scss";
</style>

<style lang="less">
@import '~@/assets/styles/vendors/vant/variables.less';
</style>

<script>
import useAuthComp from "@/core/services/composables/useAuthComp";

export default {
  name: "App",
  mounted() {
    const authComp = useAuthComp();

    authComp.logout();
    // window.addEventListener("pointerdown", e => {
    //   console.log('pointerdown');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener("pointerup", e => {
    //   console.log('pointerup');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener("pointercancel", e => {
    //   console.log('pointercancel');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener("pointermove", e => {
    //   console.log('pointermove');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('gesturestart', e => {
    //   console.log('gesturestart');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('gesturechange', e => {
    //   console.log('gesturechange');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('gestureend', e => {
    //   console.log('gestureend');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('touchstart', e => {
    //   console.log('touchstart');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('touchmove', e => {
    //   console.log('touchmove');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('touchend', e => {
    //   console.log('touchend');
    //   e.preventDefault()
    // }, false);
    // window.addEventListener('touchcancel', e => {
    //   console.log('touchcancel');
    //   e.preventDefault()
    // }, false);
    // authComp.login({
    //   body: {
    //     // email: "daivalentinenojs@gmail.com",
    //     // password: "bantal240195",
    //     email: "kegat@spindl-e.com",
    //     password: "111111",
    //     returnSecureToken: true,
    //   }
    // })
  },
  // metaInfo() {
  //   return {
  //     metaInfo: {
  //       meta: [
  //         { charset: 'utf-8' },
  //         { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' }
  //       ]
  //     }
  //   };
  // }
  
};
</script>
