import {PUSHER_AUTH_URL, PUSHER_HTTP_HOST, PUSHER_KEY} from "@/config/api";
import {createPusher} from "@/core/module/pusherModule";

export default (app) => {
    app.use(createPusher(PUSHER_KEY, {
            wsHost: PUSHER_HTTP_HOST,
            authEndpoint: PUSHER_AUTH_URL,
            // forceTLS: !0,
            // disableStats: !0,
            // enabledTransports: ["ws", "wss"],
        }),
    );
}