import {inject} from 'vue'
import Pusher from 'pusher-js';

const pusherKey = 'pusherKey';

Pusher.prototype.install = function (app, key) {
    app.provide(key || pusherKey, this);
    app.config.globalProperties.$pusher = this;
}

export function createPusher(key, options) {
    return new Pusher(key, options);
}

export function usePusher(key) {
    return inject(key ? key : pusherKey);
}