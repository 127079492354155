export default {
    request: function (req, token) {
        if (req.url === this.options.refreshData.url) {
            const refreshToken = this.token(this.options.refreshTokenName);
            req.data = {
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            }
        } else if (req.url === this.options.loginData.url) {
            //
        } else {
            // console.log('token', this.token(this.options.tokenName))
            this.drivers.http.setHeaders.call(this, req, {
                Authorization: 'Bearer ' + this.token(this.options.tokenName)
            });
        }
    },
    response: function (res) {
        const data = res.data || {}
        if (data.refreshToken != null) {
            this.token(this.options.refreshTokenName, data.refreshToken, false);
        } else if (data.refresh_token != null) {
            this.token(this.options.refreshTokenName, data.refresh_token, false);
        }
        if (data.idToken != null) {
            this.token(this.options.tokenName, data.idToken, true);
        } else if (data.id_token != null) {
            this.token(this.options.tokenName, data.id_token, true);
        }
        if (res.config.url === this.options.fetchData.url) {
            this.remember(JSON.stringify(data));
        } else if (res.config.url === this.options.refreshData.url) {
            //
        }
        return data[this.options.tokenName];
    }
};