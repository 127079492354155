import {createI18n} from 'vue-i18n'

import {locale as en} from "@/config/i18n/en";
import {locale as ch} from "@/config/i18n/ch";

const messages = {
    en, ch,
};

export default (app) => {
    app.use(createI18n({
            locale: 'en',
            fallbackLocale: 'en',
            messages,
        })
    );
}