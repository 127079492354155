import {createRouter, createWebHistory} from 'vue-router';

function loadPage(view) {
    return () => import(`@/view/pages/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [{
        path: '/',
        name: 'home',
        component: loadPage('home/Index'),
        meta: {
            auth: true
        }
    }, {
        path: '/groups/:groupId',
        name: 'groups',
        component: loadPage('home/Index'),
        meta: {
            auth: true
        }
        
    },  {
        path: '/groups/:groupId/points/:pointId',
        name: 'pointdetail',
        component: loadPage('home/Index'),
        meta: {
            auth: true
        }
        
    }, {
        path: '/',
        component: loadPage('error/Index'),
        children: [{
            path: 'error',
            name: 'error-403',
            component: loadPage('error/403'),
        }, {
            path: '/:pathNotFound(.*)*',
            name: 'error-404',
            component: loadPage('error/404'),
        }]
    }]
});

export default (app) => {
    app.router = router;
    app.use(router);
}